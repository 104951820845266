import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes, ActivatedRouteSnapshot } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { RouteEnum } from 'src/enums/utlis/route.enum';
import { AdminGuardService } from './routeGuard/adminGuard';
import { Observable } from 'rxjs';

const routes: Routes = [
  {
    path: RouteEnum.ADMIN,
    loadChildren: () => import('./administration-module/administration.module').then((m) => m.AdministrationModule),
    canActivate: [MsalGuard, adminGuard],
  },
  {
    path: RouteEnum.RESOURCES,
    loadChildren: () => import('./hosting-resources-module/hosting-resources.module').then((m) => m.HostingResourcesModule),
    canActivate: [MsalGuard],
  },
  {
    path: RouteEnum.BASEURL,
    loadChildren: () => import('./application-module/application.module').then((m) => m.ApplicationModule),
    canActivate: [MsalGuard],
  },
  { path: '**', redirectTo: '' },
];

function adminGuard(route: ActivatedRouteSnapshot): Observable<boolean> {
    return inject(AdminGuardService).canActivateGuard(route);
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    /*onSameUrlNavigation: 'reload',*/
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
