import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../shared-module/services/user-service/user.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuardService {

  constructor(public userService: UserService, public router: Router) { }

  canActivateGuard(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.userService.isCurrentUserInAdminGroup().pipe(
      tap(isAdmin => {
        if (!isAdmin) {
          this.router.navigate(['/']);
        }
      })
    );
  }

}
