import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { EMPTY, lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GraphCollection } from '../../models/graph.interface';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient) { }

  private static photosCache: Array<IPhotoCache> = [];
  private _loggedIn$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public baseUrl = environment.apiUrl.endsWith('/') ? environment.apiUrl : `${environment.apiUrl}/`;
  public loggedIn$: Observable<boolean> = this._loggedIn$.asObservable();

  private async getPhotoAsync(id: string) {
    if (!id) {
      return null;
    }
    let photoInCache = UserService.photosCache.find((x) => x.id === id);
    if (photoInCache) {
      return photoInCache.photo;
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    const url = id === 'me' ? `${GRAPH_ENDPOINT}/${id}/photo/$value` : `${GRAPH_ENDPOINT}/users/${id}/photo/$value`;
    const result$ = this.httpClient.get<Blob>(url, { headers: headers, responseType: 'blob' as 'json' }).pipe(catchError(() => EMPTY));
    const result = await lastValueFrom(result$);

    photoInCache = { id: id, photo: result };
    UserService.photosCache.push(photoInCache);
    return result;
  }

  public getCurrentUser(): Observable<MicrosoftGraph.User> {
    return this.getUserById('me');
  }

  public getUserById(id: string): Observable<MicrosoftGraph.User> {
    return this.httpClient.get<MicrosoftGraph.User>(`${GRAPH_ENDPOINT}/${id}`).pipe(share());
  }

  public getUserByEmail(mail: string) {
    return this.httpClient.get<MicrosoftGraph.User>(`${GRAPH_ENDPOINT}/users/${mail}`).pipe(
      catchError((x) => {
        if (x.status === 400) {
          return EMPTY;
        }
        return x.status
      })
    );
  }

  async getUserPhotoAsync(mail: string) {
    return this.getPhotoAsync(mail);
  }

  public isCurrentUserInAdminGroup(): Observable<boolean> {
    return this.httpClient.get<{ isAdmin: boolean }>(`${this.baseUrl}users/logged`).pipe(
      map((val: { isAdmin: boolean }) => {
        return val.isAdmin;
      })
    );
  }

  public searchUserByMail(userMail: string) {
    return this.httpClient.get<GraphCollection<MicrosoftGraph.User>>(
      `${GRAPH_ENDPOINT}/users?$filter=startswith(mail,'${userMail}') or startswith(userPrincipalName,'${userMail}')`
    );
  }

  public searchUsers(searchParam: string) {
    return this.httpClient.get<GraphCollection<MicrosoftGraph.User>>(
      `${GRAPH_ENDPOINT}/users?$filter=startswith(displayName,'${searchParam}') or startswith(givenName,'${searchParam}') or startswith(surname,'${searchParam}') or startswith(mail,'${searchParam}') or startswith(userPrincipalName,'${searchParam}')`
    );
  }

  public setLoggedIn(value: boolean) {
    this._loggedIn$.next(value);
  }
}

interface IPhotoCache {
    id: string;
    photo?: Blob;
}
