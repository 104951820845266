import { ESnakeAction, SnakeActions } from '../actions/snake.action';
import { ISnake, initialSnakeState } from '../states/snake.state';

export const snakeReducers = (
	state = initialSnakeState,
	action: SnakeActions
) : ISnake => {
	switch(action.type) {
		case ESnakeAction.SetIsDarkTheme: {
			return {
				...state,
				isDarkTheme: action.payload
			};
		}
		case ESnakeAction.SetTypeSearch: {
			return {
				...state,
				typeSearchHeader: action.payload
			};
		}
		default:
			return state;
	}
}