export enum DrawerEnum {
    SOURCE_CODE,
    HOSTING,
    MONITORING,
    SCANNERS,
    PLAT_CHILDREN,
    QUICK_VIEW_INFO,
    QUICK_VIEW_INTERACTIONS,
    QUICK_VIEW_CHILDREN,
    QUICK_VIEW_OWNERSHIP,
    QUICK_VIEW_LINKS,
    QUICK_VIEW_SUPPORT,
    QUICK_VIEW_PARTNERS,
    QUICK_VIEW_VERSIONS,
    QUICK_VIEW_LICENCES,
    QUICK_VIEW_KEY_DATES,
    QUICK_VIEW_SYNONYMS,
    QUICK_VIEW_TAGS,
    QUICK_VIEW_DEPENDENCIES,
    QUICK_VIEW_HOSTING,
    QUICK_VIEW_MONITORING,
    QUICK_VIEW_RECOVERY,
    QUICK_VIEW_STANDARDS,
    QUICK_VIEW_SOURCE_CODE,
    QUICK_VIEW_AUTHENTICATION,
    QUICK_VIEW_AUTHORIZATION_MGMT,
    QUICK_VIEW_SERVICE_ACCOUNTS,
    QUICK_VIEW_FIREWALLS,
    QUICK_VIEW_SCANNERS,
    QUICK_VIEW_AUDITS,
}
