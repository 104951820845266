export enum AdminTabs {
    REFERENCES = 0,
    LOGS = 1,
    NONE = 2,
}
export enum ApplicationsTabs {
    LIST = 0,
    GRID = 1,
	MAP = 2,
	CHARTS = 3,
	CARTOGRAPHY = 4,
    NONE = 5,
}
