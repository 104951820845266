export class NotifyHelper {
  public static durationForAuthenticationIssue: number = 60000;
  public static durationForError: number = 6000;
  public static durationForInfo: number = 2000;
  public static durationForVersion: number = 5000;
  public static maxWidth: number = 500;
  public static minWidth: number = 300;
  public static position: string = 'bottom right';

  public static getConfiguration(message: string) {
    return {
      message: message,
      position: NotifyHelper.position,
      maxWidth: NotifyHelper.maxWidth,
      minWidth: NotifyHelper.minWidth,
    };
  }

  public static getConfigurationForAuthenticationIssue(message: string, customMaxWidth?: number) {
    return {
      closeOnClick: false,
      closeOnSwipe: false,
      hideOnOutsideClick: false,
      maxWidth: customMaxWidth ?? NotifyHelper.maxWidth,
      message: message,
      minWidth: NotifyHelper.minWidth,
      position: 'center',
      shading: true
    };
  }
}
