import { TypeSearchHeader } from "src/app/application-module/enums/type-search-header.enum";

export interface ISnake {
	isDarkTheme: boolean;
	typeSearchHeader: TypeSearchHeader;
  }
  
  export const initialSnakeState: ISnake =
  {
	isDarkTheme: false,
	typeSearchHeader: localStorage.getItem('typeSearchHeader') ? parseInt(localStorage.getItem('typeSearchHeader')) : TypeSearchHeader.SEARCH,
  };