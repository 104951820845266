export enum RouteEnum {
  BASEURL = '',
  ADMIN = 'administration',
  DASHBOARD = 'home',
  REPORTING = 'reporting',
  APPLICATION_MAP = 'applications/map',
  RESOURCES = 'hosting-resources',
  RESOURCES_CONSULT = ':id',
  APPLICATION_MAP_ID = 'map/:id', //ancien APPLICATION_MAP
  APPLICATIONS = 'applications',
  ADD_APPLICATION = 'applications/add',
  EDIT_APPLICATION = 'applications/:id/edit',
  CHARTS = 'charts',
  // 
  APPLICATIONS_LIST = 'applications/list',
  APPLICATIONS_GRID = 'applications/grid',
  APPLICATIONS_CHARTS = 'applications/charts',
  APPLICATIONS_CARTOGRAPHY = 'applications/cartography',
  APPLICATION_CONSULT = 'applications/:id',
}
