import { Action, createAction, props  } from '@ngrx/store';
import { TypeSearchHeader } from 'src/app/application-module/enums/type-search-header.enum';


export enum ESnakeAction {
	SetIsDarkTheme = '[Snake] Set IsDarkTheme',
	SetTypeSearch = '[Snake] Set Type Search',
}

export function createActionWithSingleProps<T>(name: string) {
	return createAction(name, props<{ param: T }>());
}

export class SetIsDarkTheme implements Action {
	public readonly type = ESnakeAction.SetIsDarkTheme;
	constructor(public payload: boolean) {}
}

export class SetTypeSearch implements Action {
	public readonly type = ESnakeAction.SetTypeSearch;
	constructor(public payload: TypeSearchHeader) {}
}

export type SnakeActions = SetIsDarkTheme | SetTypeSearch