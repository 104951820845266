export enum GeneralSubstepUrlIndex {
    information = 0,
    ownership = 1,
    links = 2,
    support = 3,
    partners = 4,
    versions = 5,
    licenses = 6,
    keydates = 7,
    synonyms = 8,
    tags = 9,
}

export enum TechnicalSubstepUrlIndex {
    interactions = 0,
    standards = 1,
    sourcecode = 2,
    dependencies = 3,
    hosting = 4,
    monitoring = 5,
    recovery = 6,
}

export enum SecuritySubstepUrlIndex {
    authentication = 0,
    authorizationsmanagement = 1,
    serviceaccounts = 2,
    firewalls = 3,
    scanners = 4,
    audits = 5,
}
