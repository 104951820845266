
<div style="height: 100%;" class="main-menu-container">
		<div class="menu-container">

		<a  routerLinkActive="active" [routerLink]="routeEnum.BASEURL" (click)="appRoute(false, false)" class="logo">
        <img *ngIf="!isDarkTheme" src="assets/imgs/SnakeLogo.svg" alt="Snake Logo" />
		<img *ngIf="isDarkTheme" src="assets/imgs/snake-v2-sun-white.png" alt="Snake Logo" />
    </a>

    <div class="menu">
        <a class="menu-link" routerLinkActive="active" [routerLink]="routeEnum.BASEURL" (click)="appRoute(false, false)" [title]="'NAV_MENU.DASHBOARD' | translate">
            <em class="fas fa-tachometer-slowest icon"></em>
        </a>
			<div class="menu-link applications" (mouseover)="openApplicationsMenu()" (mouseout)="closeApplicationsMenu()" [ngClass]="isApplicationsRouteActive ? (enableCartography ? 'activeLong' : 'activeShort') : ''" [title]="'NAV_MENU.APPLICATIONS' | translate">
			<em class="fas fa-th icon"></em>
			<div class="secondary-menu-container" [ngClass]="isApplicationsRouteActive ? (enableCartography ? 'activeLong' : 'activeShort') : ''" >
				<a id="ROUTE_APP_LIST"
					 routerLinkActive="active"
					 [title]="'NAV_MENU.LIST' | translate"
					 [ngClass]="{ activetab: currentApplicationsTab === applicationsTabs.LIST }"
					 (click)="appRoute(true, false);updateActiveApplicationsTab(applicationsTabs.LIST);redirectToMyAppsFromList()">
					<em class="far fa-list-ul secondary-menu icon"></em>
				</a>

				<a routerLinkActive="active"
					 [title]="'NAV_MENU.GRID' | translate"
					 [ngClass]="{ activetab: currentApplicationsTab === applicationsTabs.GRID }"
					 (click)="appRoute(true, false);updateActiveApplicationsTab(applicationsTabs.GRID);redirectToMyAppsFromGrid()">
					<em class="far fa-th-list secondary-menu icon"></em>
				</a>
				<a routerLinkActive="active"
					 [routerLink]="routeEnum.APPLICATIONS_CHARTS"
					 [ngClass]="{ activetab: currentApplicationsTab === applicationsTabs.CHARTS }"
					 [title]="'NAV_MENU.CHARTS' | translate"
					 (click)="appRoute(true, false);updateActiveApplicationsTab(applicationsTabs.CHARTS)">
					<em class="fas fa-chart-pie secondary-menu icon"></em>
				</a>
					<a routerLinkActive="active"
					[title]="'NAV_MENU.MAP' | translate"
					[routerLink]="routeEnum.APPLICATION_MAP"
					[ngClass]="{ activetab: currentApplicationsTab === applicationsTabs.MAP }"
					(click)="appRoute(true, false);updateActiveApplicationsTab(applicationsTabs.MAP)">
					<em class="fas fa-chart-network secondary-menu icon"></em>
				</a>
				<a  *ngIf="enableCartography"
					routerLinkActive="active"
					[routerLink]="routeEnum.APPLICATIONS_CARTOGRAPHY"
					[ngClass]="{ activetab: currentApplicationsTab === applicationsTabs.CARTOGRAPHY }"
					[title]="'NAV_MENU.CARTOGRAPHY' | translate"
					(click)="appRoute(true, false);updateActiveApplicationsTab(applicationsTabs.CARTOGRAPHY)">
					<em class="fas fa-project-diagram secondary-menu icon"></em>
		   		</a>
			</div>
		</div>
			<a class="menu-link" routerLinkActive="active" (click)="appRoute(false, false)" [title]="'NAV_MENU.RESSOURCES' | translate" [routerLink]="routeEnum.RESOURCES">
				<em class="fas fa-server icon"></em>
			</a>
			<div 
				 [title]="'NAV_MENU.ADMINISTRATION' | translate"
				 class="menu-link admin"
				 
				 *ngIf="isCurrentUserAdmin$ | async"
				 (mouseover)="openAdminMenu()" (mouseout)="closeAdminMenu()"
				 [ngClass]="isAdminRouteActive ? 'activeShort' : ''"
				 >
				<em class="fas fa-user-cog icon"></em>
				<div class="secondary-menu-container"  [ngClass]="isAdminRouteActive ? 'activeShort' : ''">
					<a
						[title]="'NAV_MENU.REFERENCES' | translate"
						(click)="appRoute(false, true);updateActiveAdminTab(adminTabs.REFERENCES);redirectToAdmin()" [ngClass]="{ activetab: currentAdminTab === adminTabs.REFERENCES }"
						routerLinkActive="active">
						<em  class="fal fa-cog secondary-menu icon" ></em>
					</a>
					<a
						[title]="'NAV_MENU.LOGS' | translate" 
						(click)="appRoute(false, true);updateActiveAdminTab(adminTabs.LOGS);redirectToAdmin()" [ngClass]="{ activetab: currentAdminTab === adminTabs.LOGS }"
						routerLinkActive="active">
						<em class="fal fa-users secondary-menu icon"></em>
					</a>
					
					
				</div>
			</div>
		</div>
	<div class="user">
		<mgt-person  #togglePersonCard id="mgt-person" class="avatar" person-query="me" show-presence="true"  avatar-size="auto" view="avatar" (click)="openPersonCard()"></mgt-person>

		<div #snakeVersionTooltip *ngIf="version" class="app-version" (click)="openReleaseNotes()" [ngClass]="{'no-url': !releaseNotesUrl}">
			{{version}}

			<dx-tooltip *ngIf="releaseNotesUrl" [target]="snakeVersionTooltip" showEvent="dxhoverstart" hideEvent="dxhoverend">
				<div style="white-space: pre-wrap;text-align: left;">
					<span>
						Open release note
					</span>
				</div>
			</dx-tooltip>
		</div>

		<div class="icon-help">
			<a [href]="helpSiteUrl" target="_blank" #helpSiteUrlTooltip class="first-icon-help">
			  <i class="fas fa-circle-info"></i>
			  <dx-tooltip [target]="helpSiteUrlTooltip" showEvent="dxhoverstart" hideEvent="dxhoverend">
				<div style="white-space: pre-wrap;text-align: left;">
					<span>
						I need help! <br/>
						Open me the documentation site (please)
					</span>
				</div>
			</dx-tooltip>
			</a>
			<a [href]="feedbackSiteUrl" target="_blank" #feedbackSiteUrlTooltip>
			  <i class="fas fa-circle-question"></i>
			  <dx-tooltip [target]="feedbackSiteUrlTooltip" showEvent="dxhoverstart" hideEvent="dxhoverend">
				<div style="white-space: pre-wrap;text-align: left;">
					<span>
						Comment ? Question ? Suggestion ? <br/>
						I want to give my feedback :)
					</span>
				</div>
			</dx-tooltip>
			</a>
		</div>

		<mat-card #personCard *ngIf="personCardIsOpen" class="secondary">
			<div class="mgt-card-header">
				<mgt-person class="avatar" person-query="me" view="avatar"></mgt-person>
				<div class="mgt-card-name onSecondary">{{ username }}</div>
			</div>
			<div class="mgt-card-content">
				<div class="toggle-container">
					<div class="category onPrimary">Theme :</div>
					<div class="value onPrimary">{{isDefaultTheme ? "Default" : themeSelected}}</div>
					<button #menuThemeButton class="button" mat-button [matMenuTriggerFor]="menuTheme" #themeMenuTrigger="matMenuTrigger" (click)="menuThemeOpen = !menuThemeOpen">
						<em *ngIf="!menuThemeOpen" class="far fa-angle-right onTertiary personcard-sidemenu"></em>
						<em *ngIf="menuThemeOpen" class="far fa-angle-left onTertiary personcard-sidemenu" ></em>
					</button>
					<mat-menu #menuTheme="matMenu" xPosition="after" class="secondary" (closed)="menuThemeClosed()">
						<button [ngClass]="isDefaultTheme ? 'selected' : ''" mat-menu-item (click)="themeControl('Default', true)">Default</button>
						<button [ngClass]="themeSelected == 'Light' && !isDefaultTheme ? 'selected' : ''" mat-menu-item (click)="themeControl('Light', false)">Light</button>
						<button [ngClass]="themeSelected == 'Dark' && !isDefaultTheme ? 'selected' : ''" mat-menu-item (click)="themeControl('Dark', false)">Dark</button>
					</mat-menu>
				</div>
				<div class="toggle-container">
					<div class="category onPrimary">Preference :</div>
					<div class="value onPrimary">{{typeSearchHeader === 0 ? "Search" : "Queries"}}</div>
					<button #menuPrefButton class="button" mat-button [matMenuTriggerFor]="menuPref" #prefMenuTrigger="matMenuTrigger" (click)="menuPrefOpen = !menuPrefOpen">
						<em *ngIf="!menuPrefOpen" class="far fa-angle-right onTertiary personcard-sidemenu"></em>
						<em *ngIf="menuPrefOpen" class="far fa-angle-left onTertiary personcard-sidemenu" ></em>
					</button>
					<mat-menu #menuPref="matMenu" xPosition="after" class="secondary" (closed)="menuPrefClosed()">
							<button [ngClass]="typeSearchHeader === 0 ? 'selected' : ''" mat-menu-item (click)="changeTypeSearch(0)">Search</button>
							<button [ngClass]="typeSearchHeader === 1 ? 'selected' : ''" mat-menu-item (click)="changeTypeSearch(1)">Queries</button>
					</mat-menu>
				</div>
			</div>
			<div class="mgt-card-footer onTertiary" (click)="logout()">
				<em class="fas fa-power-off"></em>
				<div>Log out</div>
			</div>
		</mat-card>
    </div>
	
</div>
</div>
