import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
    DxAccordionModule,
    DxBoxModule,
    DxButtonModule,
    DxChartModule,
    DxCheckBoxModule,
    DxContextMenuModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxFileUploaderModule,
    DxFilterBuilderModule,
    DxGalleryModule,
    DxLoadIndicatorModule,
    DxLoadPanelModule,
    DxLookupModule,
    DxMultiViewModule,
    DxNumberBoxModule,
    DxPieChartModule,
    DxPopupModule,
    DxRadioGroupModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxTabPanelModule,
    DxTabsModule,
    DxTagBoxModule,
    DxTemplateModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxTooltipModule,
    DxValidatorModule,
} from 'devextreme-angular';
import { HttpLoaderFactory } from '../app.module';
import { ToggleSliderComponent } from './components/toggle-slider/toggle-slider.component';
import { ToggleOnOffComponent } from './components/toggle-on-off/toggle-on-off.component';
import { QueryBuilderSelectComponent } from './components/query-builder-select/query-builder-select.component';
import { SelectApplicationComponent } from './components/select-application/select-application.component';
import { SelectRegionComponent } from './components/select-region/select-region.component';
import { PopupWarningQueryManagerComponent } from './components/popup-warning-query-manager/popup-warning-query-manager.component';
import { JoinPipe } from './pipes/join.pipe';
import { PluckPipe } from './pipes/pluck.pipe';
import { PrefixPipe } from './pipes/prefix.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { PeopleViewerComponent } from './components/people-viewer/people-viewer.component';
import { PersonViewerComponent } from './components/person-viewer/person-viewer.component';
import { LogoCheckComponent } from './components/logo-check/logo-check.component';
import { LogoResourcesComponent } from './components/logo-resources/logo-resources.component';
import { LogoInteractionDirectionComponent } from './components/logo-interaction-direction/logo-interaction-direction.component';
import { FilterBuilderComponent } from './components/filter-builder/filter-builder.component';
import { PopupQueryManagementComponent } from './components/popup-query-management/popup-query-management.component';
import { SnakeAppInfoComponent } from './components/snake-app-info/snake-app-info.component';
import { SnakeAppCardComponent } from './components/snake-app-card/snake-app-card.component';
import { SnakeApplicationObsolescenceComponent } from './components/snake/application-obsolescence/application-obsolescence.component';
import { SnakeApplicationStatusComponent } from './components/snake/application-status/application-status.component';
import { SnakeCompletionTopicComponent } from './components/snake/completion-topic/completion-topic.component';
import { SnakeLogoCheckComponent } from './components/snake/logo-check/logo-check.component';
import { SnakeLogoCheckSimpleComponent } from './components/snake/logo-check-simple/logo-check-simple.component';
import { SnakeLogoCybergateComponent } from './components/snake/logo-cybergate/logo-cybergate.component';
import { SnakePersonViewerComponent } from './components/snake/person-viewer/person-viewer.component';
import { LogoApplicationComponent } from './components/logo-application/logo-application.component';
import { PopupHistoryComponent } from './components/popup-history/popup-history.component';
import { ExternalLinkComponent } from './components/external-link/external-link.component';
import { RatingIconComponent } from './components/rating-icon/rating-icon.component';
import { PercentageCircleComponent } from './components/percentage-circle/percentage-circle.component';
import { CodePercentageBarComponent } from './components/code-percentage-bar/code-percentage-bar.component';
import { SelectBusinessCapabilitiesComponent } from './components/select-business-capabilities/select-business-capabilities.component';
import { SelectEntitiesComponent } from './components/select-entities/select-entities.component';
import { SelectDepartmentsComponent } from './components/select-departments/select-departments.component';
import { PopupDepartmentComponent } from './components/popup-department/popup-department.component';
import { SelectAppStatusComponent } from './components/select-app-status/select-app-status.component';
import { SelectCriticityComponent } from './components/select-criticity/select-criticity.component';
import { SelectCategoryComponent } from './components/select-category/select-category.component';
import { SelectUsersRangeComponent } from './components/select-users-range/select-users-range.component';
import { SelectStandardComponent } from './components/select-standard/select-standard.component';
import { SelectRegulatoryConstraintComponent } from './components/select-regulatory-constraint/select-regulatory-constraint.component';
import { SelectPartnerComponent } from './components/select-partner/select-partner.component';
import { PopupPartnerComponent } from './components/popup-partner/popup-partner.component';
import { SelectAuthenticationModeComponent } from './components/select-authentication-mode/select-authentication-mode.component';
import { SelectAuthorizationModeComponent } from './components/select-authorization-mode/select-authorization-mode.component';
import { PopupApiManagerComponent } from './components/popup-api-manager/popup-api-manager.component';
import { PopupAuthorizationModeComponent } from './components/popup-authorization-mode/popup-authorization-mode.component';
import { PopupCodeRepositoryPlatformComponent } from './components/popup-code-repository-platform/popup-code-repository-platform.component';
import { PopupCodeScannerPlatformComponent } from './components/popup-code-scanner-platform/popup-code-scanner-platform.component';
import { PopupFirewallPlatformComponent } from './components/popup-firewall-platform/popup-firewall-platform.component';
import { PopupHostingProviderComponent } from './components/popup-hosting-provider/popup-hosting-provider.component';
import { PopupMonitoringPlatformComponent } from './components/popup-monitoring-platform/popup-monitoring-platform.component';
import { PopupPartnerRoleComponent } from './components/popup-partner-role/popup-partner-role.component';
import { PopupSecurityScannerPlatformComponent } from './components/popup-security-scanner-platform/popup-security-scanner-platform.component';
import { PopupTechnologyVersionComponent } from './components/popup-technology-version/popup-technology-version.component';
import { PopupTechnologyComponent } from './components/popup-technology/popup-technology.component';
import { SelectApiManagerComponent } from './components/select-api-manager/select-api-manager.component';
import { SelectApplicationOwnerComponent } from './components/select-app-owner/select-app-owner.component';
import { SelectCodeRepositoryPlatformComponent } from './components/select-code-repository-platform/select-code-repository-platform.component';
import { SelectCodeScannerPlatformComponent } from './components/select-code-scanner-platform/select-code-scanner-platform.component';
import { SelectFirewallPlatformComponent } from './components/select-firewall-platform/select-firewall-platform.component';
import { SelectHostingProviderComponent } from './components/select-hosting-provider/select-hosting-provider.component';
import { SelectHostingResourcesTypeComponent } from './components/select-hosting-resources-type/select-hosting-resources-type.component';
import { SelectHostingTypeComponent } from './components/select-hosting-type/select-hosting-type.component';
import { SelectInteractionDirectionComponent } from './components/select-interaction-direction/select-interaction-direction.component';
import { SelectLevelComponent } from './components/select-level/select-level.component';
import { SelectMonitoringPlatformComponent } from './components/select-monitoring-platform/select-monitoring-platform.component';
import { SelectPartnerRoleComponent } from './components/select-partner-role/select-partner-role.component';
import { SelectProtocolComponent } from './components/select-protocol/select-protocol.component';
import { SelectQueueComponent } from './components/select-queue/select-queue.component';
import { SelectResilienceResultComponent } from './components/select-resilience-result/select-resilience-result.component';
import { SelectRpoDurationComponent } from './components/select-rpo-duration/select-rpo-duration.component';
import { SelectRtoDurationComponent } from './components/select-rto-duration/select-rto-duration.component';
import { SelectRunResponsibilityComponent } from './components/select-run-responsibility/select-run-responsibility.component';
import { SelectSecurityScannerPlatformComponent } from './components/select-security-scanner-platform/select-security-scanner-platform.component';
import { SelectTechnologyVersionComponent } from './components/select-technology-version/select-technology-version.component';
import { SelectTechnologyComponent } from './components/select-technology/select-technology.component';
import { PopupLevelComponent } from './components/popup-level/popup-level.component';
import { PopupQueueComponent } from './components/popup-queue/popup-queue.component';
import { PopupWarningGridSaveComponent } from './components/popup-warning-grid-save/popup-warning-grid-save.component';
import { PeoplePickerComponent } from './components/people-picker/people-picker.component';
import { SnakeApplicationEditStatusComponent } from './components/snake/application-edit-status/application-edit-status.component';
import { TextboxUrlComponent } from './components/textbox-url/textbox-url.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { FilterPipe } from './pipes/filter.pipe';
import { SelectInteractionExecutionModeComponent } from './components/select-interaction-executionMode/select-interaction-executionMode.component';
import { SelectConcernedEntitiesComponent } from './components/select-concernedEntities/select-concernedEntities.component';
import { SelectResourceComponent } from './components/select-resource/select-resource.component';
import { PopupMergedInComponent } from './components/popup-merged-in/popup-merged-in.component';
import { SelectProductFamiliesComponent } from './components/select-product-families/select-product-families.component';

@NgModule({
  providers: [
  ],
  imports: [
    NgSelectModule,
    FormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    RouterModule,
    CommonModule,
    ClipboardModule,
    DxButtonModule,
    DxLoadPanelModule,
    DxLookupModule,
    DxPopupModule,
    DxSelectBoxModule,
    DxTabPanelModule,
    DxTabsModule,
    DxTagBoxModule,
    DxTextBoxModule,
    DxDateBoxModule,
    DxTooltipModule,
    DxValidatorModule,
    DxScrollViewModule,
    DxContextMenuModule,
    DxFilterBuilderModule,
    DxTemplateModule,
    ReactiveFormsModule,
  ],
  exports: [
    TranslateModule,
    DxAccordionModule,
    DxBoxModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxFileUploaderModule,
    DxGalleryModule,
    DxLoadIndicatorModule,
    DxLoadPanelModule,
    DxMultiViewModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxRadioGroupModule,
    DxSelectBoxModule,
    DxScrollViewModule,
    DxSwitchModule,
    DxTabPanelModule,
    DxTabsModule,
    DxTagBoxModule,
    DxTemplateModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxTooltipModule,
    DxValidatorModule,
    ReactiveFormsModule,
    DxLookupModule,
    DxPieChartModule,
    DxChartModule,
    DxContextMenuModule,
    DxFilterBuilderModule,
    ToggleSliderComponent,
    ToggleOnOffComponent,
    QueryBuilderSelectComponent,
    SelectApplicationComponent,
    SelectResourceComponent,
    SelectRegionComponent,
    PopupWarningQueryManagerComponent,
    JoinPipe,
    FilterPipe,
    PluckPipe,
    PrefixPipe,
    ReversePipe,
    DateFormatPipe,
    PeopleViewerComponent,
    PersonViewerComponent,
    LogoCheckComponent,
    LogoResourcesComponent,
    FilterBuilderComponent,
    PopupQueryManagementComponent,
    SnakeAppInfoComponent,
    SnakeAppCardComponent,
    SnakeApplicationObsolescenceComponent,
    SnakeApplicationStatusComponent,
    SnakeCompletionTopicComponent,
    SnakeLogoCheckComponent,
    SnakeLogoCheckSimpleComponent,
    SnakeLogoCybergateComponent,
    SnakePersonViewerComponent,
    LogoApplicationComponent,
    LogoInteractionDirectionComponent,
    PopupHistoryComponent,
    ExternalLinkComponent,
    RatingIconComponent,
    PercentageCircleComponent,
    CodePercentageBarComponent,

    SelectApiManagerComponent,
    SelectApplicationComponent,
    SelectApplicationOwnerComponent,
    SelectAppStatusComponent,
    SelectAuthenticationModeComponent,
    SelectAuthorizationModeComponent,
    SelectBusinessCapabilitiesComponent,
    SelectCategoryComponent,
    SelectCodeRepositoryPlatformComponent,
    SelectCodeScannerPlatformComponent,
    SelectCriticityComponent,
    SelectDepartmentsComponent,
    SelectEntitiesComponent,
    SelectConcernedEntitiesComponent,
    SelectFirewallPlatformComponent,
    SelectHostingProviderComponent,
    SelectHostingResourcesTypeComponent,
    SelectHostingTypeComponent,
    SelectMonitoringPlatformComponent,
    SelectInteractionDirectionComponent,
    SelectInteractionExecutionModeComponent,
    SelectPartnerComponent,
    SelectPartnerRoleComponent,
    SelectProductFamiliesComponent,
    SelectProtocolComponent,
    SelectRegionComponent,
    SelectRegulatoryConstraintComponent,
    SelectResilienceResultComponent,
    SelectRpoDurationComponent,
    SelectRtoDurationComponent,
    SelectRunResponsibilityComponent,
    SelectSecurityScannerPlatformComponent,
    SelectStandardComponent,
    SelectTechnologyComponent,
    SelectTechnologyVersionComponent,
    SelectUsersRangeComponent,
    SelectLevelComponent,
    SelectQueueComponent,

    PopupApiManagerComponent,
    PopupAuthorizationModeComponent,
    PopupCodeRepositoryPlatformComponent,
    PopupCodeScannerPlatformComponent,
    PopupDepartmentComponent,
    PopupMergedInComponent,
    PopupFirewallPlatformComponent,
    PopupHostingProviderComponent,
    PopupHostingProviderComponent,
    PopupMonitoringPlatformComponent,
    PopupPartnerComponent,
    PopupPartnerRoleComponent,
    PopupSecurityScannerPlatformComponent,
    PopupTechnologyComponent,
    PopupTechnologyVersionComponent,
    PeoplePickerComponent,

    SnakeApplicationEditStatusComponent,
    TextboxUrlComponent
  ],
  declarations: [
    ToggleSliderComponent,
    ToggleOnOffComponent,
    QueryBuilderSelectComponent,
    SelectApplicationComponent,
    SelectResourceComponent,
    SelectRegionComponent,
    PopupWarningQueryManagerComponent,
    JoinPipe,
    FilterPipe,
    PluckPipe,
    PrefixPipe,
    ReversePipe,
    DateFormatPipe,
    PeopleViewerComponent,
    PersonViewerComponent,
    LogoCheckComponent,
    LogoResourcesComponent,
    FilterBuilderComponent,
    PopupQueryManagementComponent,
    SnakeAppInfoComponent,
    SnakeAppCardComponent,
    SnakeApplicationObsolescenceComponent,
    SnakeApplicationStatusComponent,
    SnakeCompletionTopicComponent,
    SnakeLogoCheckComponent,
    SnakeLogoCheckSimpleComponent,
    SnakeLogoCybergateComponent,
    SnakePersonViewerComponent,
    LogoApplicationComponent,
    LogoInteractionDirectionComponent,
    PopupHistoryComponent,
    ExternalLinkComponent,
    RatingIconComponent,
    PercentageCircleComponent,
    CodePercentageBarComponent,

    SelectApiManagerComponent,
    SelectApplicationComponent,
    SelectApplicationOwnerComponent,
    SelectAppStatusComponent,
    SelectAuthenticationModeComponent,
    SelectAuthorizationModeComponent,
    SelectBusinessCapabilitiesComponent,
    SelectCategoryComponent,
    SelectCodeRepositoryPlatformComponent,
    SelectCodeScannerPlatformComponent,
    SelectCriticityComponent,
    SelectDepartmentsComponent,
    SelectEntitiesComponent,
    SelectConcernedEntitiesComponent,
    SelectFirewallPlatformComponent,
    SelectHostingProviderComponent,
    SelectHostingResourcesTypeComponent,
    SelectHostingTypeComponent,
    SelectMonitoringPlatformComponent,
    SelectInteractionDirectionComponent,
    SelectInteractionExecutionModeComponent,
    SelectPartnerComponent,
    SelectPartnerRoleComponent,
    SelectProductFamiliesComponent,
    SelectProtocolComponent,
    SelectRegionComponent,
    SelectRegulatoryConstraintComponent,
    SelectResilienceResultComponent,
    SelectRpoDurationComponent,
    SelectRtoDurationComponent,
    SelectRunResponsibilityComponent,
    SelectSecurityScannerPlatformComponent,
    SelectStandardComponent,
    SelectTechnologyComponent,
    SelectTechnologyVersionComponent,
    SelectUsersRangeComponent,
    SelectLevelComponent,
    SelectQueueComponent,

    PopupApiManagerComponent,
    PopupAuthorizationModeComponent,
    PopupCodeRepositoryPlatformComponent,
    PopupCodeScannerPlatformComponent,
    PopupDepartmentComponent,
    PopupMergedInComponent,
    PopupFirewallPlatformComponent,
    PopupHostingProviderComponent,
    PopupHostingProviderComponent,
    PopupMonitoringPlatformComponent,
    PopupPartnerComponent,
    PopupPartnerRoleComponent,
    PopupSecurityScannerPlatformComponent,
    PopupTechnologyComponent,
    PopupTechnologyVersionComponent,

    PopupLevelComponent,
    PopupQueueComponent,
    PopupWarningGridSaveComponent,
    PeoplePickerComponent,

    SnakeApplicationEditStatusComponent,
    TextboxUrlComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static getModuleWithProviders(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }

  static forChild() {
    return SharedModule.getModuleWithProviders();
  }
  static forRoot() {
    return SharedModule.getModuleWithProviders();
  }
}
