import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard, MsalGuardConfiguration, MsalInterceptor,
    MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService
} from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SnakeConfig, SnakeConfigManager } from "@pernod-ricard/snake-sdk";
import { DxDrawerModule, DxLoadPanelModule, DxScrollViewModule, DxTooltipModule } from 'devextreme-angular';
import { lastValueFrom } from 'rxjs';
import { appReducers } from '../app/store/reducers/app.reducer';
import { environment } from '../environments/environment';
import { ApiModule } from './api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './root-components/app.component';
import { NavMenuComponent } from './root-components/layout-components/nav-menu/nav-menu.component';
import { SharedModule } from './shared-module/shared.module';
import config from 'devextreme/core/config';
import { licenseKey } from '../devextreme-license';

export const graphScopes = [
  'contacts.read',
  'email',
  'group.read.all',
  'mail.readbasic',
  'openid',
  'people.read',
  'people.read.all',
  'presence.read',
  'presence.read.all',
  'profile',
  'sites.read.all',
  'user.read',
  'user.read.all',
  'user.readbasic.all'
];

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureClientId,
      authority: environment.azureAuthority,
      postLogoutRedirectUri: environment.azureRedirectUrl,
      redirectUri: environment.azureRedirectUrl
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false
      }
    }
  });
}

export const msalGuardConfig = {
  interactionType: InteractionType.Popup, // MSAL Guard Configuration
  authRequest: {
    scopes: [environment.azureClientScope],
    authority: environment.azureAuthority
  }
} as MsalGuardConfiguration;

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Popup };
}


export function appInitializerFactory(translate: TranslateService) {
  return async () => {
    translate.setDefaultLang('en_EN');
    const language$ = translate.use('en_EN')
    return lastValueFrom(language$);
  };
}

export function getAadScopes(): string[] {
  let scopes = graphScopes;
  scopes.push(environment.azureClientScope);
  if (environment.site24x7BotAadClientScope) scopes.push(environment.site24x7BotAadClientScope);
  if (environment.sonarQubeBotAadClientScope) scopes.push(environment.sonarQubeBotAadClientScope);
  if (environment.cyberGateApiScope) scopes.push(environment.cyberGateApiScope);
  return scopes;
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());
}

export function msalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/*', graphScopes);

  protectedResourceMap.set(environment.sonarQubeBotUrl + '*', [environment.sonarQubeBotAadClientScope]);
  protectedResourceMap.set(environment.site24x7BotUrl + '*', [environment.site24x7BotAadClientScope]);
  protectedResourceMap.set(environment.cyberGateApiUrl + '*', [environment.cyberGateApiScope]);

  const apiUrlPattern = environment.apiUrl.endsWith('/') ? environment.apiUrl + '*' : environment.apiUrl + '/*';
  protectedResourceMap.set(apiUrlPattern, [environment.azureClientScope]);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent
  ],
  imports: [
    MsalModule,
    BrowserAnimationsModule,
    MatMenuModule,
    HttpClientModule,
    AppRoutingModule,
    ApiModule,
    DxScrollViewModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    DxLoadPanelModule,
    DxDrawerModule,
    DxTooltipModule,
    SharedModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: APP_ID,
      useValue: 'ng-cli-universal'
    },
    MsalGuard,
    MsalBroadcastService,
    MsalService,
    SnakeConfigManager,
    SnakeConfig,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})

export class AppModule {
  constructor(private snakeConfigManager: SnakeConfigManager) {
    this.snakeConfigManager.init(
      {
        oauthClientId: environment.azureClientId,
        oauthAuthority: environment.azureAuthority,
        enableCyberGateBadge: (environment.cyberGateEnabled == 'true'),
        cyberGateApi: {
          url: environment.cyberGateApiUrl,
          oauthScopes: [environment.cyberGateApiScope]
        } as any,
        redirectUrl: environment.azureRedirectUrl,
        snakeApi: {
          url: environment.apiUrl,
          oauthScopes: [environment.azureClientScope],
          sonarQubeBotUrl: environment.sonarQubeBotUrl,
          site24x7BotUrl: environment.site24x7BotUrl
        } as any

      } as any
    );
    config({ licenseKey });
  }
}
