import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import {
    AuthenticationResult,
    EventMessage,
    EventType,
    InteractionStatus
} from '@azure/msal-browser';
import { Msal2Provider, Msal2PublicClientApplicationConfig, Providers, TemplateHelper } from '@microsoft/mgt';
import { PublicClientApplication } from '@microsoft/microsoft-graph-types';
import { Store } from '@ngrx/store';
import { DxDrawerComponent } from 'devextreme-angular';
import { locale } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';
import themes from "devextreme/ui/themes";
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { RouteEnum } from 'src/enums/utlis/route.enum';
import { ThemeEnum } from 'src/enums/utlis/theme.enum';
import { MSALInstanceFactory, graphScopes } from '../app.module';
import { DrawerEnum } from '../application-module/enums/drawer.enum';
import { NotifyHelper } from '../shared-module/helpers/notify.helper';
import { LoaderService } from '../shared-module/services/loader.service';
import { NavigationService } from '../shared-module/services/navigation.service';
import { UserService } from '../shared-module/services/user-service/user.service';
import { SetIsDarkTheme } from '../store/actions/snake.action';
import { selectIsDarkTheme } from '../store/selectors/snake.selector';
import { IAppState } from '../store/states/app.state';
import { refreshTheme } from "devextreme/viz/themes";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	public currentAppId!: string;
	public currentRoute!: string;
	public isIframe = false;
	public isHome = false;
	public loading$!: Observable<boolean>;
	load: boolean = true;
	public loggedIn = false;
	public previousRoute!: string;
	public routeEnum = RouteEnum;
	public appGuid: string | undefined;
  
	private readonly _destroying$ = new Subject<void>();
	private _loadPanelSize = '150px';
	private _unsubscriber$: Subject<boolean> = new Subject();
		
	title = 'Angular material dark mode';
	ThemeEnum = ThemeEnum;

	@HostBinding('class') className = '';

	toggleControl = new FormControl(false);

	isDrawerOpen: boolean = false;
	drawerData!: Observable<any>;
	drawerType: DrawerEnum = 0;
	drawerEnum = DrawerEnum;
	isDrawerPlatform: boolean | undefined;

	themeSelected: string = ThemeEnum.LIGHT;
	isDefaultTheme: boolean = false;
	defaultThemeSelected: string =  ThemeEnum.LIGHT
  isDarkTheme: boolean;

  signInButtonOptions: any
  signInPopupVisible: boolean = false;

	@ViewChild('drawer')
	drawer!: DxDrawerComponent;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) public msalGuardConfig: MsalGuardConfiguration,
    private router: Router,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userService: UserService,
    private loaderService: LoaderService,
    private navigationService: NavigationService,
    private overlay: OverlayContainer,
    private _store: Store<IAppState>
  ) {
    this.themeSelected = localStorage.getItem('themeSelected') ? localStorage.getItem('themeSelected') : ThemeEnum.LIGHT;
    locale("en");
    this.currentRoute = '';
    this.previousRoute = '';
    this.currentAppId = '';
    this.isHome = false;
    const that = this;

    this.signInButtonOptions = {
      icon: 'key',
      stylingMode: 'contained',
      text: 'Sign In',
      type: 'success',
      onClick(e) {
        that.authService.loginPopup().subscribe((result) => {
          that.signInPopupVisible = false;
          that.router.navigateByUrl(`/${that.currentRoute}`);
        });
      },
    };

    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd)
    ).subscribe({
      next: (event) => {
        this.handleNavigationEvent(event);
      }
    });
  }

  handleNavigationEvent(event: any) {
    const regExpId = /\d+/;
    if (event instanceof NavigationEnd) {
      this.handleNavigationEndEvent(event, regExpId);
    }

    if (event instanceof NavigationStart) {
      this.handleNavigationStartEvent(event, regExpId);
    }
  }

  handleNavigationEndEvent(event: any, regExpId: RegExp) {
    const hasId = regExpId.test(this.currentRoute);

    let  currentUrlWithoutFragment = event.url.split('#')[0];
    let  previousUrlWithoutFragment = this.previousRoute.split('#')[0];

    if (currentUrlWithoutFragment.startsWith('/')) {
      currentUrlWithoutFragment = currentUrlWithoutFragment.slice(1);
    }
    if (previousUrlWithoutFragment.startsWith('/')) {
        previousUrlWithoutFragment = previousUrlWithoutFragment.slice(1);
    }

    if (this.currentAppId && (!hasId || regExpId.exec(this.currentRoute)[0] !== this.currentAppId) && currentUrlWithoutFragment !== previousUrlWithoutFragment) {
      this.navigationService.resetCurrentConsultCategory();
      this.navigationService.resetSelectedSubStep();
    }
    this.previousRoute = this.currentRoute;
    this.isHome = [RouteEnum.BASEURL.toString(), RouteEnum.DASHBOARD.toString(), '/'].includes(this.currentRoute);
    
    if (!event.urlAfterRedirects.includes('applications')) {
      this.navigationService.setLastSearchListApp('');
    }
  }

  handleNavigationStartEvent(event: any, regExpId: RegExp) {
    if (regExpId.test(this.currentRoute)) {
      this.currentAppId = regExpId.exec(this.currentRoute)[0];
    }
    this.currentRoute = event.url.replace(/\//, '');

    if (event.url.endsWith('/applications')) {
      const fromAppForm =
        this.previousRoute && this.previousRoute.indexOf('applications/') > -1 && regExpId.test(this.previousRoute);
      const navState = this.router.getCurrentNavigation().extras.state;

      if (fromAppForm && navState && navState.resetFilters) {
        const newState = {
          filterOnAliveStatus: navState.filterOnAliveStatus,
          filterOnCurrentUser: navState.filterOnCurrentUser,
          resetFilters: false,
        };
        this.router.navigateByUrl(event.url, { state: newState });
      } else if (event?.restoredState?.resetFilters) {
        const newState = {
          filterOnAliveStatus: event.restoredState.filterOnAliveStatus,
          filterOnCurrentUser: event.restoredState.filterOnCurrentUser,
          resetFilters: false,
        };
        this.router.navigateByUrl(event.url, { state: newState });
      }
    }
  }


  ngOnInit() {
    this.initTheme()
    this.isIframe = window !== window.parent && !window.opener;
    const firstUrl = window.location.pathname;
    const firstHash =  window.location.hash;
    
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (result) => {
          const loginError = result.error as any;
          if (loginError.errorCode === 'popup_window_error') {
            this.signInPopupVisible = true;
          }
          else if (loginError.errorCode === 'user_cancelled') {
            notify(NotifyHelper.getConfigurationForAuthenticationIssue('You have cancelled the authentication\nPlease refresh the page to retry or close the window.', null), 'error', NotifyHelper.durationForAuthenticationIssue);
          }
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        const activeAccount = this.authService.instance.getActiveAccount();
        if (activeAccount) {
          const msalInstance = MSALInstanceFactory();
          const initializeMsal = async () => {
            await msalInstance.initialize();
            const msalProviderConfig = {
              publicClientApplication: msalInstance as PublicClientApplication,
              scopes: graphScopes
            } as Msal2PublicClientApplicationConfig;
            Providers.globalProvider = new Msal2Provider(msalProviderConfig);
            TemplateHelper.setBindingSyntax('[[', ']]');

            this.userService.setLoggedIn(true);

          if (firstUrl === '/') {
              this.router.navigateByUrl('/home');
          } else {
              this.router.navigateByUrl(firstUrl + firstHash);
          }
          };
          initializeMsal();
        }

        this.loading$ = this.loaderService.isLoading;

        this.navigationService.drawerStatus.pipe(takeUntil(this._unsubscriber$)).subscribe({
          next: (v) => {
            if (v) {
              if (this.isDrawerOpen && this.drawerType === v.type && this.appGuid === v.appId) {
                this.isDrawerOpen = false;
              }
              else {
                this.isDrawerOpen = true;
                this.drawerData = v.data;
                this.drawerType = v.type;
                this.isDrawerPlatform = v.isPlatform;
                this.appGuid = v.appId;
              }
            }
          },
        });
      });
  }
  
  initTheme() {
    this.checkThemeInStore();
    this.checkThemeSelected();
    this.checkLocalStorage();
    this.setDefaultTheme();
    this.addDarkModeListener();

    if (this.themeSelected === ThemeEnum.DARK) {
      this.loadCustomThemeCss('./assets/styles/snake.dx.darkmoon.css');
    } else {
      this.loadCustomThemeCss('./assets/styles/snake.dx.light.css');
    }
  }
  
  setDefaultTheme() {
    if (window.matchMedia?.('(prefers-color-scheme: dark)')?.matches) {
      this.defaultThemeSelected = ThemeEnum.DARK;
      this.changeThemeIfDefault(ThemeEnum.DARK);
    }
    else {
      this.defaultThemeSelected = ThemeEnum.LIGHT;
      this.changeThemeIfDefault(ThemeEnum.LIGHT);
    }
  }
  
  addDarkModeListener() {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      if (event.matches) {
        this.defaultThemeSelected = ThemeEnum.DARK;
        this.changeThemeIfDefault(ThemeEnum.DARK);
      }
      else {
        this.defaultThemeSelected = ThemeEnum.LIGHT;
        this.changeThemeIfDefault(ThemeEnum.LIGHT);
      }
    });
  }
  
  changeThemeIfDefault(theme: ThemeEnum) {
    if (this.isDefaultTheme === true) {
      if (theme === ThemeEnum.DARK) this.changeToDarkTheme();
      else this.changeToLightTheme();
    }
  }

  checkThemeInStore() {
    this._store.select(selectIsDarkTheme).pipe(tap(theme => this.isDarkTheme = theme)).subscribe();
	}

  checkThemeSelected() {
    if (this.themeSelected === ThemeEnum.DARK) this.changeToDarkTheme();
    else this.changeToLightTheme();
	}

  checkLocalStorage() {
    if (localStorage.getItem('isDefaultTheme') === "true") this.isDefaultTheme = true;
    else if (localStorage.getItem('isDefaultTheme') === "false") this.isDefaultTheme = false;
    else this.isDefaultTheme = false;
	}

	closeDrawer() {
		this.drawer.instance.hide();
	}

	ngOnDestroy() {
		this._unsubscriber$.complete();
	}

	public showingLoadPanel() {
		const loadPanel = document.getElementsByClassName(
			'dx-loadpanel-indicator dx-loadindicator dx-widget'
		) as HTMLCollectionOf<HTMLElement>;
		if (loadPanel.length !== 0) {
			loadPanel[0].style.height = this._loadPanelSize;
			loadPanel[0].style.width = this._loadPanelSize;
		}
	}

	changeTheme(data: any) {
		this.isDefaultTheme = data.isDefaultTheme;
		localStorage.setItem('isDefaultTheme',  data.isDefaultTheme);

    if (data.theme === ThemeEnum.DARK)
      this.changeToDarkTheme()
    else if (data.theme === ThemeEnum.LIGHT)
      this.changeToLightTheme()
    else if (data.theme === ThemeEnum.DEFAULT) {
      if (this.defaultThemeSelected === ThemeEnum.LIGHT) this.changeToLightTheme()
      else this.changeToDarkTheme()
		}
	}

	changeToLightTheme() {
		this.themeSelected = ThemeEnum.LIGHT;
    localStorage.setItem('themeSelected', ThemeEnum.LIGHT);
    window.localStorage.setItem("dx-theme", "generic.light");
    this._store.dispatch(new SetIsDarkTheme(false));
    
    // Load light theme CSS dynamically
    this.loadCustomThemeCss('./assets/styles/snake.dx.light.css');

    this.className = "lightMode";
		this.overlay.getContainerElement().classList.add("lightMode");
    this.overlay.getContainerElement().classList.remove("darkMode");
    themes.current("generic.light");
    refreshTheme();
	}
	changeToDarkTheme() {
		this.themeSelected = ThemeEnum.DARK;
    localStorage.setItem('themeSelected', ThemeEnum.DARK);
    window.localStorage.setItem("dx-theme", "generic.darkmoon");
    this._store.dispatch(new SetIsDarkTheme(true));

    // Load dark theme CSS dynamically
    this.loadCustomThemeCss('./assets/styles/snake.dx.darkmoon.css');

    this.className = "darkMode";
		this.overlay.getContainerElement().classList.add("darkMode");
    this.overlay.getContainerElement().classList.remove("lightMode");
    themes.current("generic.darkmoon");
    refreshTheme();
	}

  // Helper method to dynamically load a CSS file
  loadCustomThemeCss(href: string) {
    let customThemeLink = document.getElementById('custom-theme-css') as HTMLLinkElement;
    
    if (customThemeLink) {
      customThemeLink.href = href;
    } else {
      customThemeLink = document.createElement('link');
      customThemeLink.id = 'custom-theme-css';
      customThemeLink.rel = 'stylesheet';
      customThemeLink.href = href;
      document.head.appendChild(customThemeLink);
    }
  }

}
