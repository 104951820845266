import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private _isLoading$ = new BehaviorSubject(false);
    public isLoading = this._isLoading$.asObservable();
    public current = this._isLoading$.value;

    public setLoading(loading: boolean) {
        setTimeout(() => this._isLoading$.next(loading));
    }
}
