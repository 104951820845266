import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { UserService } from '../shared-module/services/user-service/user.service';

import {
    ApiManagersService,
    AuthenticationModesService,
    BusinessDepartmentsService,
    CodeRepositoryPlatformsService,
    CodeScannerPlatformsService,
    CriticitiesService,
    FirewallPlatformsService,
    HostingProvidersService,
    HostingTypesService,
    ItDepartmentsService,
    PartnersService,
    PartnersRolesService,
    ProtocolsService,
    RegionsService,
    EntitiesService,
    SecurityScannerPlatformsService,
    StandardsService,
    TechnologiesService,
    TechnologiesVersionsService,
    AadClientsService,
    ApplicationsService,
    AuditsService,
    CodeRepositoriesService,
    CodeScannersService,
    DependenciesService,
    FirewallsService,
    HostingResourcesService,
    InteractionsService,
    VersionsService,
    KeyDatesService,
    LinksService,
    ApplicationPartnersService,
    SecurityScannersService,
    TagsService,
    LicensesService,
    ApplicationAuthenticationModesService,
    RpoDurationsService,
    RtoDurationsService,
    StatisticsService,
} from '@pernod-ricard/snake-sdk';

@NgModule({
    imports: [HttpClientModule],
    declarations: [],
    providers: [
        UserService,
        ApiManagersService,
        AuthenticationModesService,
        BusinessDepartmentsService,
        CodeRepositoryPlatformsService,
        CodeScannerPlatformsService,
        CriticitiesService,
        FirewallPlatformsService,
        HostingProvidersService,
        HostingTypesService,
        ItDepartmentsService,
        PartnersService,
        PartnersRolesService,
        ProtocolsService,
        RegionsService,
        EntitiesService,
        SecurityScannerPlatformsService,
        StandardsService,
        TechnologiesService,
        TechnologiesVersionsService,
        AadClientsService,
        ApplicationsService,
        AuditsService,
        CodeRepositoriesService,
        CodeScannersService,
        DependenciesService,
        FirewallsService,
        HostingResourcesService,
        InteractionsService,
        VersionsService,
        KeyDatesService,
        LinksService,
        ApplicationPartnersService,
        SecurityScannersService,
        TagsService,
        LicensesService,
        ApplicationAuthenticationModesService,
        RpoDurationsService,
        RtoDurationsService,
        StatisticsService,
    ],
})
export class ApiModule {}
