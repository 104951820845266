export const environment = {
  production: true,
  apiUrl: 'https://api-dev.pernod-ricard.io/v3/snake/',
  azureClientId: '3fcddba9-46f3-4a8e-bff4-96de8bc3be95',
  azureClientScope: 'api://536eaf3d-81b0-4d68-b187-827c4ebe3083/ReadWrite.All',
  azureAuthority: 'https://login.microsoftonline.com/pernodricard.onmicrosoft.com',
  azureRedirectUrl: 'https://snake-dev.pernod-ricard.app',
  sonarQubeBotUrl: 'https://api.pernod-ricard.io/v3/snake/bots/sonarqube/measures/',
  sonarQubeBotAadClientScope: 'api://944edd1f-0f7c-4005-b4c8-006792ab660f/Read.All',
  site24x7BotUrl: 'https://api.pernod-ricard.io/v3/snake/bots/site24x7/monitors/',
  site24x7BotAadClientScope: 'api://a447d4c7-b374-498d-bd49-7ab34e13fe5b/Read.All',
  cyberGateWebUrl: 'https://cyberstagegate.pernod-ricard.app/?snakeId=',
  cyberGateApiUrl: 'https://api.pernod-ricard.io/v1/cyber-stage-gate/',
  cyberGateApiScope: 'api://a7914db3-58d0-4ce5-aa04-2d08eb65abc2/ReadWrite.All',
  cyberGateEnabled: 'true',
  cartographyEnabled: 'true',
  helpSiteUrl: 'https://archi.pernod-ricard.wiki/en/solutions/snake/v3',
  feedbackSiteUrl: 'https://snake-feedback.pernod-ricard.io'
};
