import { createSelector } from '@ngrx/store';
import { IAppState } from '../states/app.state';
import { ISnake } from '../states/snake.state';

export const selectSnake = (state: IAppState) => state.snake;

export const selectIsDarkTheme = createSelector(
	selectSnake,
	(state: ISnake) => state.isDarkTheme
);

export const selectTypeSearchHeader = createSelector(
	selectSnake,
	(state: ISnake) => state.typeSearchHeader
);
