<div id="app-layout">
	<img *ngIf="themeSelected === ThemeEnum.LIGHT" src="assets/imgs/Background@3x.png"
	alt=""
	style="width: 100%;
	height: 100vh;
	z-index: 0;
	background-color: #fff;">
		<div *ngIf="themeSelected === ThemeEnum.DARK" style="width: 100%;height: 100vh;z-index: 0;background-image: linear-gradient(36deg, #000131 28%, #0e2f5f 98%);"></div>
		<app-nav-menu [currentRoute]="currentRoute" [themeSelected]="themeSelected" [isDefaultTheme]="isDefaultTheme" (changeThemeSelected)=changeTheme($event)> </app-nav-menu>

		<dx-drawer
			#drawer
			[openedStateMode]="'overlap'"
			[position]="'right'"
			[revealMode]="'slide'"
			[(opened)]="isDrawerOpen"
			[closeOnOutsideClick]="true"
			class="scroll-list-container"
		>
			<div id="page-container">
				<div id="page-content" [ngClass]="{ 'full-page': isHome }">
					<router-outlet *ngIf="!isIframe"></router-outlet>
				</div>
			</div>
	</dx-drawer>
</div>


<dx-load-panel
    indicatorSrc="../../assets/imgs/snake-loader.gif"
    message=""
    [hideOnOutsideClick]="false"
    [wrapperAttr]="{ id: 'appLoadPanel', class: 'appLoadPanelIndicator' }"
    [height]="200"
    [position]="{ of: '#app-layout' }"
    [showPane]="false"
    [visible]="loading$ | async"
    [width]="200"
    (onShowing)="showingLoadPanel()"
>
</dx-load-panel>

<dx-popup titleTemplate="title"
					[dragEnabled]="false"
					[height]="280"
					[hideOnOutsideClick]="false"
					[showCloseButton]="false"
					[shading]="true"
					[showTitle]="true"
					[width]="500"
					[(visible)]="signInPopupVisible">
	<div *dxTemplate="let data of 'title'">
		<div style="text-align:center;">
			<img src="../../assets/imgs/SnakeLogo.svg" alt="" style="width:4rem;" />
		</div>
	</div>
	<div>
		<p>Your browser doesn't authorize popups for Snake, so we cannot automatically authenticate you.</p>
		<p>Please authorize popups for Snake and refresh the page to allow authentication or click on the <strong><em>Sign In</em></strong> button below to authenticate you without authorizing popups.</p>
	</div>
	<dxi-toolbar-item location="center" toolbar="bottom" widget="dxButton" [options]="signInButtonOptions"></dxi-toolbar-item>
</dx-popup>
