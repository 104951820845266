import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AdminTabs, ApplicationsTabs } from 'src/app/application-module/enums/amin-tabs.enum';
import { TypeSearchHeader } from 'src/app/application-module/enums/type-search-header.enum';
import { NavigationService } from 'src/app/shared-module/services/navigation.service';
import { SetTypeSearch } from 'src/app/store/actions/snake.action';
import { IAppState } from 'src/app/store/states/app.state';
import { RouteEnum } from 'src/enums/utlis/route.enum';
import { ThemeEnum } from 'src/enums/utlis/theme.enum';
import version from '../../../../../../ClientApp/package.json';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../shared-module/services/user-service/user.service';
import { selectIsDarkTheme, selectTypeSearchHeader } from '../../../store/selectors/snake.selector';
import { VersionsService } from '@pernod-ricard/snake-sdk';
import notify from 'devextreme/ui/notify';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit, AfterViewInit {
  @Input()
  currentRoute!: string;

  @Input() themeSelected: string;
  @Input() isDefaultTheme: boolean;
  @Output() changeThemeSelected: EventEmitter<any> = new EventEmitter<any>();
  public version: string = version.version;
  ThemeEnum: ThemeEnum;

  public isCurrentUserAdmin$: Observable<boolean>;
  public isCurrentUserLoggedIn: boolean = false;
  public routeEnum = RouteEnum;
  public username!: string;

  public currentAdminTab = AdminTabs.REFERENCES;
  public currentApplicationsTab = ApplicationsTabs.LIST;

  public adminTabs = AdminTabs;
  public applicationsTabs = ApplicationsTabs;
  public personCardIsOpen: boolean = false;
  public enableCartography: boolean = false;
  public helpSiteUrl: string = "";
  public feedbackSiteUrl : string = "";
  public releaseNotesUrl: string = "";

  private _unsubscriber$: Subject<void> = new Subject();

  @ViewChild('themeMenuTrigger') themeMenuTrigger: MatMenuTrigger;

  @ViewChild('togglePersonCard') togglePersonCard: ElementRef;
  @ViewChild('personCard') personCard: ElementRef;
  @ViewChild('menuThemeButton') menuThemeButton: ElementRef;


  @ViewChild('prefMenuTrigger') prefMenuTrigger: MatMenuTrigger;
  @ViewChild('menuPrefButton') menuPrefButton: ElementRef;

  menuThemeOpen: boolean = false;
  menuPrefOpen: boolean = false;
  isDarkTheme: boolean = null;
  isApplicationsRouteActive: boolean = false;
  isAdminRouteActive: boolean = false;
  typeSearchHeader: TypeSearchHeader = TypeSearchHeader.SEARCH;

  constructor(
    private userService: UserService,
    private navigationService: NavigationService,
    private authService: MsalService,
    private renderer: Renderer2,
    private _store: Store<IAppState>,
    private router: Router,
    private versionsService: VersionsService,
    private translateService: TranslateService,
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      const target: any = e?.target;
      const className = target?.className;
      if (typeof className === 'string') {
        if ((!this.togglePersonCard?.nativeElement.contains(e?.target) && !this.personCard?.nativeElement?.contains(e?.target)
        && !this.menuThemeButton?.nativeElement.contains(e?.target)
        && !target?.className?.includes("mat-menu-item")
        && !target?.className?.includes("mat-menu-content")
        && !target?.className?.includes("personcard-sidemenu")
        && this.menuThemeOpen !== true) ||
        (!this.togglePersonCard?.nativeElement.contains(e?.target) && !this.personCard?.nativeElement?.contains(e?.target)
          && !this.menuPrefButton?.nativeElement.contains(e?.target)
          && !target?.className?.includes("mat-menu-item")
          && !target?.className?.includes("mat-menu-content")
          && !target?.className?.includes("personcard-sidemenu")
          && this.menuPrefOpen !== true)
      ) {
        this.personCardIsOpen = false;
      }
      }
    });
  }

  ngOnInit() {
    this._store.select(selectIsDarkTheme).pipe(
      tap(theme => {
        this.isDarkTheme = theme
      })
    ).subscribe();

    this._store.select(selectTypeSearchHeader).pipe(
      tap(typeSearch => {
        this.typeSearchHeader = typeSearch
      })
    ).subscribe();

    if (window.location.href.includes('/applications')) {
      this.isApplicationsRouteActive = true
      if (window.location.href.includes('/applications/list')) {
        this.updateActiveApplicationsTab(ApplicationsTabs.LIST)
      }
      if (window.location.href.includes('/applications/grid')) {
        this.updateActiveApplicationsTab(ApplicationsTabs.GRID)
      }
      if (window.location.href.includes('/applications/map')) {
        this.updateActiveApplicationsTab(ApplicationsTabs.MAP)
      }
      if (window.location.href.includes('/applications/charts')) {
        this.updateActiveApplicationsTab(ApplicationsTabs.CHARTS)
      }
      if (window.location.href.includes('/applications/cartography')) {
        this.updateActiveApplicationsTab(ApplicationsTabs.CARTOGRAPHY)
      }
    }

    this.versionsService.getByApplication("1", this.version).pipe(
      tap(result => {
        if (result?.[0]?.releaseNotesUrl) {
          this.releaseNotesUrl = result[0]?.releaseNotesUrl;

          const lastVersion = localStorage.getItem('last-version-app-snake');
          const currentVersion = result[0]?.version;

          if (!lastVersion || lastVersion !== currentVersion) {
            if (lastVersion && (lastVersion !== currentVersion) ) {
              notify(
                {
                  message: "",
                  type: 'info',
                  displayTime: 30000,
                  position: 'bottom center',
                  width: "58rem",
                  closeOnClick: true,
                  contentTemplate: () => {
                    const element = document.createElement('div');
                    element.textContent = this.translateService.instant('MISCELLANEOUS.NEW_VERSION_MESSAGE_PART1');
                    const link = document.createElement('a');
                    link.textContent = this.translateService.instant('MISCELLANEOUS.NEW_VERSION_MESSAGE_PART2', { version: currentVersion });
                    link.href = this.releaseNotesUrl;
                    link.target = "_blank";
                    link.style.color = 'white'; // set link color to white
                    // link.style.textDecoration = 'none'; // remove underline
                    element.appendChild(link);
                    return element;
                  }
              }
            );
            }
            localStorage.setItem('last-version-app-snake', currentVersion);
          }
        }
      })
    ).subscribe()

  }

  ngAfterViewInit(): void {
    this.userService.loggedIn$.subscribe((value: boolean) => {
      if (!value) return;

      this.helpSiteUrl  = environment.helpSiteUrl;
      this.feedbackSiteUrl = environment.feedbackSiteUrl;

      if (environment.cartographyEnabled === 'true') this.enableCartography = true;
      this.isCurrentUserAdmin$ = this.userService.isCurrentUserInAdminGroup();
      this.isCurrentUserLoggedIn = true;

      this.userService.getCurrentUser().subscribe({ next: (user) => (this.username = user.displayName) });
      this.navigationService.selectedApplicationsTab.pipe(takeUntil(this._unsubscriber$)).subscribe({
        next: (v) => {
          this.currentApplicationsTab = v;
        }
      });
      this.navigationService.selectedAmdinTab.pipe(takeUntil(this._unsubscriber$)).subscribe({
        next: (v) => {
          this.currentAdminTab = v;
        }
      });
    });
  }

  openReleaseNotes() {
    if (this.releaseNotesUrl && this.releaseNotesUrl !== "") {
      window.open(this.releaseNotesUrl, '_blank');
    }
  }

  logout() {
    this.authService.logoutRedirect();
  }

  updateActiveAdminTab(t: AdminTabs) {
    this.navigationService.setCurrentAdminCategory(t);
  }

  updateActiveApplicationsTab(t: ApplicationsTabs) {
    this.navigationService.setCurrentApplicationsTab(t);
  }

  ngOnDestroy() {
    this._unsubscriber$.next();
    this._unsubscriber$.complete();
  }

  openPersonCard() {
    this.personCardIsOpen = !this.personCardIsOpen;
  }

  themeControl(theme: string, isDefaultTheme: boolean) {
    const data = {
      theme: theme,
      isDefaultTheme: isDefaultTheme
    }
    this.changeThemeSelected.emit(data);
  }
  menuThemeClosed() {
    setTimeout(() => {
      this.menuThemeOpen = false;
    }, 100);
  }

  menuPrefClosed() {
    setTimeout(() => {
      this.menuPrefOpen = false;
    }, 100);
  }

  goToAppList() {
    this.router.navigate(['applications/list']);
  }

  appRoute(isAppRoute: boolean, isAdminRoute: boolean) {
    this.isApplicationsRouteActive = isAppRoute;
    this.isAdminRouteActive = isAdminRoute;
  }

  openApplicationsMenu() {
    if (!this.isApplicationsRouteActive) {
      this.isApplicationsRouteActive = true;
      this.updateActiveApplicationsTab(ApplicationsTabs.NONE)
    }
  }

  closeApplicationsMenu() {
    if (this.currentApplicationsTab === ApplicationsTabs.NONE) {
      this.isApplicationsRouteActive = false;
    }
  }

  openAdminMenu() {
    if (!this.isAdminRouteActive) {
      this.isAdminRouteActive = true;
      this.updateActiveAdminTab(AdminTabs.NONE)
    }
  }

  closeAdminMenu() {
    if (this.currentAdminTab === AdminTabs.NONE) {
      this.isAdminRouteActive = false;
    }
  }

  changeTypeSearch(typeSearch) {
    localStorage.setItem('typeSearchHeader', typeSearch);
    this._store.dispatch(new SetTypeSearch(typeSearch))
  }

  public redirectToAdmin() {
    this.router.navigateByUrl(this.routeEnum.ADMIN);
  }


  public redirectToMyAppsFromList() {
    this.router.navigateByUrl(this.routeEnum.APPLICATIONS_LIST, {
      state: {
        filterOnCurrentUser: true,
        filterOnAliveStatus: true,
        resetFilters: true,
      },
    });
  }

  public redirectToMyAppsFromGrid() {
    this.router.navigateByUrl(this.routeEnum.APPLICATIONS_GRID, {
      state: {
        filterOnCurrentUser: true,
        filterOnAliveStatus: true,
        resetFilters: true,
      },
    });
  }
}
