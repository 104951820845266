import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { AdminTabs, ApplicationsTabs } from 'src/app/application-module/enums/amin-tabs.enum';
import { ApplicationSteps } from 'src/app/application-module/enums/applications-steps.enum';
import { DrawerEnum } from 'src/app/application-module/enums/drawer.enum';
import { GeneralSubstepUrlIndex, SecuritySubstepUrlIndex, TechnicalSubstepUrlIndex } from '../enums/substepsIndexEnum';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private _currentConsultCategory: BehaviorSubject<any> = new BehaviorSubject(ApplicationSteps.General);
    public currentConsultCategory: Observable<ApplicationSteps> = this._currentConsultCategory.asObservable();

    private _selectedStep: ReplaySubject<{ index: number; name: string }> = new ReplaySubject(1);
    public selectedStep: Observable<{ index: number; name: string }> = this._selectedStep.asObservable();

    private _selectedAmdinTab: ReplaySubject<number> = new ReplaySubject(1);
    public selectedAmdinTab: Observable<number> = this._selectedAmdinTab.asObservable();

    private _selectedApplicationsTab: ReplaySubject<number> = new ReplaySubject(1);
    public selectedApplicationsTab: Observable<number> = this._selectedApplicationsTab.asObservable();

    private _drawerStatus: Subject<{ type: DrawerEnum; data: Observable<any>; appId?: string; isPlatform?: boolean }> = new Subject();
    public drawerStatus: Observable<{ type: DrawerEnum; data: Observable<any>; appId?: string; isPlatform?: boolean }> =
        this._drawerStatus.asObservable();

    private _lastSearchListApp: BehaviorSubject<{ search: any; extended: any }> = new BehaviorSubject({
        search: '',
        extended: false,
    });
    public lastSearchListApp: Observable<{ search: string; extended: boolean }> = this._lastSearchListApp.asObservable();

    constructor(route: ActivatedRoute) {
        const subscription = route.fragment.pipe().subscribe({
            next: (fragment) => {
                if (fragment) {
                    const selectedStep: any = fragment.substring(0, fragment.indexOf('-'));
                    const selectedSubstep: any = fragment.substring(fragment.lastIndexOf('-') + 1);
                    let selectedSubstepIndex;
                    switch (selectedStep) {
                        case 'general':
                            this.setCurrentConsultCategory(ApplicationSteps.General);
                            selectedSubstepIndex = GeneralSubstepUrlIndex[selectedSubstep];
                            break;
                        case 'technical':
                            this.setCurrentConsultCategory(ApplicationSteps.Technical);
                            selectedSubstepIndex = TechnicalSubstepUrlIndex[selectedSubstep];
                            break;
                        case 'security':
                            this.setCurrentConsultCategory(ApplicationSteps.Security);
                            selectedSubstepIndex = SecuritySubstepUrlIndex[selectedSubstep];
                            break;
                    }
                    this.goToSelectSubStep({ index: parseInt(selectedSubstepIndex), name: selectedSubstep });
                    subscription.unsubscribe();
                }
            },
            error: (err) => {
                console.error(err);
            },
        });
    }

    public setCurrentConsultCategory(cat: ApplicationSteps) {
        this._currentConsultCategory.next(cat);
    }

    public resetCurrentConsultCategory() {
        this._currentConsultCategory.next(ApplicationSteps.General);
    }

    public goToSelectSubStep(substep: { index: number; name: string }) {
        this._selectedStep.next(substep);
    }

    public resetSelectedSubStep() {
		const selectedSubstepIndex = GeneralSubstepUrlIndex.information;
		this._selectedStep.next({ index: selectedSubstepIndex, name: 'information' });
    }

    public setCurrentAdminCategory(tab: AdminTabs) {
        this._selectedAmdinTab.next(tab);
    }

    public setCurrentApplicationsTab(tab: ApplicationsTabs) {
        this._selectedApplicationsTab.next(tab);
    }

    public setLastSearchListApp(search: string, extended = false) {
        this._lastSearchListApp.next({ search, extended });
    }

    setDrawerStatus(drawerData: { type: DrawerEnum; data: Observable<any>; isPlatform?: boolean; appId?: string }) {
        this._drawerStatus.next(drawerData);
    }
}
